import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Não encontrado" />
    <section className="intro">
      <div className="intro-container">
        <h1>404</h1>
        <p>Página não encontrada. <Link to="/">Voltar à página inicial</Link></p>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
